import React, { useEffect, useState } from "react";
import { Grid, LinearProgress, MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { Formik } from "formik";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import * as Yup from "yup";
import axios from "axios";
import { getRegionalOffices } from "../api/getRegionalOffices";
import { getDistrictOffices } from "../api/getDistrictOffices";
import { SnackbarProvider, enqueueSnackbar } from "notistack";

export default function Form() {
  const ColorButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#86a315",
    "&:hover": {
      backgroundColor: "#587d18",
    },
  }));

  const [regionalOffices, setRegionalOffices] = useState(null);
  const [districtOffices, setDistrictOffices] = useState(null);

  useEffect(() => {
    getRegionalOffices()
      .then((res) => {
        setRegionalOffices(res.data);
      })
      .catch((error) => {
        enqueueSnackbar("[Chorągwie] Błąd pobierania danych: " + error, {
          variant: "error",
        });
      });
  }, [getRegionalOffices]);

  const schema = Yup.object().shape({
    nameSurname: Yup.string().required("Imię i nazwisko jest wymagane!"),
    email: Yup.string().email().required(),
  });

  return (
    <Box id="form">
      <SnackbarProvider dense preventDuplicate>
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pt: { xs: 14, sm: 20 },
            pb: { xs: 8, sm: 12 },
          }}
        >
          <Stack spacing={2} useFlexGap>
            <Typography
              component="h2"
              variant="h4"
              color="text.primary"
              sx={{
                width: { sm: "100%", md: "100%" },
              }}
            >
              Formularz zgłoszenia przekroczenia granic
            </Typography>
            <Typography
              textAlign="left"
              color="text.secondary"
              sx={{ alignSelf: "center" }}
            >
              <Formik
                initialValues={{
                  affectsYou: "",
                  nameSurname: "",
                  phoneNumber: "",
                  role: "",
                  age: "",
                  nameSurnameVictim: "",
                  regionalOffice: "",
                  districtOffice: "",
                  ageVictim: "",
                  nameSurnameGuilty: "",
                  infoGuilty: "",
                  description: "",
                  isCrime: "",
                  policeInformed: "",
                  actions: "",
                  isCamp: "",
                  nameSurnameCampCommisioner: "",
                  phoneNumberCampCommisioner: "",
                  isDistrictCommisionerGuilty: "",
                  isRegionalOfficeCommisionerGuilty: "",
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    alert(JSON.stringify(values, null, 2));
                    setSubmitting(false);
                  }, 400);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  handleReset,
                  setFieldValue,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container>
                      <Grid xs={12}>
                        <h2>Osoba zgłaszająca</h2>
                        <Typography>
                          Jeśli podasz swoje dane kontaktowe w tym formularzu,
                          zostaną one wykorzystane do kontaktu z Tobą w celu
                          weryfikacji tego, jak czujesz się po dokonaniu
                          zgłoszenia i czy nie potrzebujesz dodatkowej pomocy
                          oraz zostaną przekazane osobie prowadzącej interwencję
                          w tej sprawie (jeśli nie jesteś nią Ty). Pytanie o
                          funkcję w ZHP pozwoli nam wyłapać te osoby, które będą
                          zaangażowane w dalszy proces prowadzenia interwencji w
                          tej sprawie (np. komendant hufca, kierownik
                          wypoczynku). W takiej sytuacji nasza rozmowa będzie
                          miała też na celu opracowanie z Tobą planu działań,
                          które należy podjąć.
                        </Typography>
                      </Grid>
                      <Grid xs={12} style={{ marginTop: 15 }}>
                        <FormControl>
                          <FormLabel id="daffectsYou">
                            Czy sytuacja przekroczenia granic lub przemocy
                            dotyczy Ciebie? Czy jesteś osobą pokrzywdzoną w tej
                            sprawie?
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="affectsYou"
                            name="affectsYou"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.affectsYou}
                            error={errors.affectsYou}
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Tak"
                              name="affectsYou"
                              value="true"
                            />
                            <FormControlLabel
                              value="false"
                              control={<Radio />}
                              label="Nie"
                              name="affectsYou"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      {(values.affectsYou == "false" ||
                        values.affectsYou == "") && (
                        <>
                          <Grid item xs={12} style={{ marginTop: 15 }}>
                            <TextField
                              fullWidth
                              name="nameSurname"
                              id="nameSurname"
                              variant="filled"
                              error={
                                touched.nameSurname &&
                                Boolean(errors.nameSurname)
                              }
                              helperText={
                                touched.nameSurname && errors.nameSurname
                                  ? errors.nameSurname
                                  : "Pamiętaj, że podanie nam swoich danych jest dobrowolne!"
                              }
                              label="Twoje imię i nazwisko"
                              value={values.nameSurname}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid item xs={12} style={{ marginTop: 15 }}>
                            <TextField
                              fullWidth
                              name="phoneNumber"
                              id="phoneNumber"
                              variant="filled"
                              error={
                                touched.phoneNumber &&
                                Boolean(errors.phoneNumber)
                              }
                              helperText={
                                touched.phoneNumber && errors.phoneNumber
                                  ? errors.phoneNumber
                                  : "Twój telefon zostanie wykorzystany do nawiązania kontaktu z Tobą w celu weryfikacji tego, jak czujesz się po dokonaniu zgłoszenia i czy nie potrzebujesz dodatkowej pomocy oraz zostaną przekazane osobie prowadzącej interwencję w tej sprawie (jeśli nie jesteś nią Ty)."
                              }
                              label="Twój numer telefonu"
                              value={values.phoneNumber}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="number"
                            />
                          </Grid>
                          <Grid item xs={12} style={{ marginTop: 15 }}>
                            <TextField
                              fullWidth
                              name="role"
                              id="role"
                              variant="filled"
                              error={touched.role && Boolean(errors.role)}
                              helperText={
                                touched.role && errors.role
                                  ? errors.role
                                  : "Jaką funkcję pełnisz w ZHP? Jeśli nie pełnisz żadnej, przejdź dalej."
                              }
                              label="Twoja funkcja"
                              value={values.role}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid item xs={12} style={{ marginTop: 15 }}>
                            <TextField
                              fullWidth
                              name="age"
                              id="age"
                              variant="filled"
                              error={touched.age && Boolean(errors.age)}
                              helperText={
                                touched.age && errors.age
                                  ? errors.age
                                  : "Ile masz lat?"
                              }
                              label="Twój wiek"
                              value={values.age}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="number"
                            />
                          </Grid>
                        </>
                      )}
                      <Grid xs={12} style={{ marginTop: 15 }}>
                        <Typography>
                          Kto był uczestnikiem sytuacji, do której doszło?
                          Identyfikacja uczestników sytuacji pomoże podjąć
                          odpowiednie działania w tej sprawie. Jeśli jednak nie
                          chcesz podawać jakichś danych, nie musisz tego robić.
                        </Typography>
                        <h2>Osoba poszkodowana</h2>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="nameSurnameVictim"
                          id="nameSurnameVictim"
                          variant="filled"
                          error={
                            touched.nameSurnameVictim &&
                            Boolean(errors.nameSurnameVictim)
                          }
                          helperText={
                            touched.nameSurnameVictim &&
                            errors.nameSurnameVictim
                              ? errors.nameSurnameVictim
                              : "Osoba, która doświadczyła przekroczenia granic."
                          }
                          label="Imię i nazwisko osoby poszkodowanej"
                          value={values.nameSurnameVictim}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <TextField
                          fullWidth
                          select
                          name="regionalOffice"
                          id="regionalOffice"
                          variant="filled"
                          error={
                            touched.regionalOffice &&
                            Boolean(errors.regionalOffice)
                          }
                          helperText={
                            touched.regionalOffice && errors.regionalOffice
                              ? errors.regionalOffice
                              : "Po wybraniu Chorągwi z listy załadują się Hufce."
                          }
                          value={values.regionalOffice}
                          onChange={(e) => {
                            setFieldValue("districtOffice", "");
                            setDistrictOffices(null);
                            handleChange(e);
                            getDistrictOffices(e.target.value)
                              .then((res) => {
                                setDistrictOffices(res.data);
                              })
                              .catch((error) => {
                                enqueueSnackbar(
                                  "[Hufce] Błąd pobierania danych: " + error,
                                  {
                                    variant: "error",
                                  }
                                );
                              });
                          }}
                          onBlur={handleBlur}
                          label="Z jakiej chorągwi jest osoba, która doświadczyła przekroczenia granic?"
                        >
                          <MenuItem value="" disabled>
                            <em>Wybierz z listy</em>
                          </MenuItem>
                          {regionalOffices &&
                            regionalOffices.map((office) => (
                              <MenuItem
                                key={office.objectKey}
                                value={office.objectKey}
                              >
                                {office.label}
                              </MenuItem>
                            ))}
                        </TextField>
                      </Grid>
                      {values.regionalOffice != "" && (
                        <>
                          <Grid item xs={12} style={{ marginTop: 15 }}>
                            {districtOffices == null && (
                              <LinearProgress color="success" />
                            )}
                            <TextField
                              fullWidth
                              select
                              name="districtOffice"
                              id="districtOffice"
                              variant="filled"
                              error={
                                touched.districtOffice &&
                                Boolean(errors.districtOffice)
                              }
                              helperText={
                                touched.districtOffice && errors.districtOffice
                              }
                              value={values.districtOffice}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              label="Z jakiego hufca jest osoba, która doświadczyła przekroczenia granic?"
                              disabled={districtOffices == null}
                            >
                              <MenuItem value="" disabled>
                                <em>Wybierz z listy</em>
                              </MenuItem>
                              {districtOffices &&
                                districtOffices.map((office) => (
                                  <MenuItem
                                    key={office.objectKey}
                                    value={office.objectKey}
                                  >
                                    {office.label}
                                  </MenuItem>
                                ))}
                            </TextField>
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <TextField
                          fullWidth
                          select
                          name="ageVictim"
                          id="ageVictim"
                          variant="filled"
                          error={touched.ageVictim && Boolean(errors.ageVictim)}
                          helperText={touched.ageVictim && errors.ageVictim}
                          value={values.ageVictim}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          label="W jakim wieku jest osoba, która doświadczyła przekroczenia granic?"
                        >
                          <MenuItem value="" disabled>
                            <em>Wybierz z listy</em>
                          </MenuItem>
                          <MenuItem value={"do13"}>
                            jest dzieckiem (do 13 roku życia)
                          </MenuItem>
                          <MenuItem value={"od14do17"}>
                            jest nastolatkiem (od 14 do 17 roku życia)
                          </MenuItem>
                          <MenuItem value={"od18do21"}>
                            jest młodym dorosłym (od 18 do 21 roku życia)
                          </MenuItem>
                          <MenuItem value={"od22"}>jest osobą dorosłą</MenuItem>
                          <MenuItem value={"niewiem"}>nie wiem</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid xs={12} style={{ marginTop: 15 }}>
                        <h2>Osoba przekraczająca granice</h2>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="nameSurnameGuilty"
                          id="nameSurnameGuilty"
                          variant="filled"
                          error={
                            touched.nameSurnameGuilty &&
                            Boolean(errors.nameSurnameGuilty)
                          }
                          helperText={
                            touched.nameSurnameGuilty &&
                            errors.nameSurnameGuilty
                              ? errors.nameSurnameGuilty
                              : "Jak nazywała się osoba, która dopuściła się naruszenia granic? Jeśli nie chcesz na tym etapie podawać tej informacji, przejdź dalej."
                          }
                          label="Imię i nazwisko osoby przekraczającej granice"
                          value={values.nameSurnameGuilty}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          name="infoGuilty"
                          id="infoGuilty"
                          variant="filled"
                          error={
                            touched.infoGuilty && Boolean(errors.infoGuilty)
                          }
                          helperText={
                            touched.infoGuilty && errors.infoGuilty
                              ? errors.infoGuilty
                              : "Podaj: wiek, funkcję, przynależność do środowiska (np. hufiec, chorągiew). Jeśli nie znasz jakichś danych osoby, która przekroczyła granice, napisz tak dużo, jak wiesz."
                          }
                          label="Kim jest przekraczający?"
                          value={values.infoGuilty}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid xs={12} style={{ marginTop: 15 }}>
                        <h2>Opis sytuacji</h2>
                        <Typography>
                          W tej sekcji poprosimy Cię o opisanie przebiegu
                          sytuacji, do której doszło. Podaj te informacje, które
                          znasz. Jeśli nie potrafisz odpowiedzieć na jakieś
                          pytanie, omiń je.
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          name="description"
                          id="description"
                          variant="filled"
                          error={
                            touched.description && Boolean(errors.description)
                          }
                          helperText={touched.description && errors.description}
                          label="Opis sytuacji przekroczenia granic"
                          value={values.description}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid xs={12} style={{ marginTop: 15 }}>
                        <FormControl>
                          <FormLabel id="isCrime">
                            Czy sytuacja nosi znamiona przestępstwa?
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="isCrime"
                            name="isCrime"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.isCrime}
                            error={errors.isCrime}
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Tak"
                              name="isCrime"
                              value="true"
                            />
                            <FormControlLabel
                              value="false"
                              control={<Radio />}
                              label="Nie"
                              name="isCrime"
                            />
                            <FormControlLabel
                              value="idk"
                              control={<Radio />}
                              label="Nie wiem"
                              name="isCrime"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      {values.isCrime == "true" && (
                        <Grid xs={12} style={{ marginTop: 15 }}>
                          <FormControl>
                            <FormLabel id="policeInformed">
                              Czy o sytuacji poinformowano policję?
                            </FormLabel>
                            <RadioGroup
                              aria-labelledby="policeInformed"
                              name="policeInformed"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.policeInformed}
                              error={errors.policeInformed}
                            >
                              <FormControlLabel
                                control={<Radio />}
                                label="Tak"
                                name="policeInformed"
                                value="true"
                              />
                              <FormControlLabel
                                value="false"
                                control={<Radio />}
                                label="Nie"
                                name="policeInformed"
                              />
                              <FormControlLabel
                                value="idk"
                                control={<Radio />}
                                label="Nie wiem"
                                name="policeInformed"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      )}
                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <TextField
                          fullWidth
                          multiline
                          rows={4}
                          name="actions"
                          id="actions"
                          variant="filled"
                          error={touched.actions && Boolean(errors.actions)}
                          helperText={touched.actions && errors.actions}
                          label="Czy jakieś działania zostały już podjęte?"
                          placeholder="Czy komenda hufca lub kierownik wypoczynku zareagował - w jaki sposób? Czy zostały przeprowadzone jakieś rozmowy w tej sprawie? Czy zostały wyciągnięte konsekwencje?"
                          value={values.actions}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Grid>
                      <Grid xs={12} style={{ marginTop: 15 }}>
                        <FormControl>
                          <FormLabel id="isCamp">
                            Czy do tej sytuacji doszło na obozie?
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="isCamp"
                            name="isCamp"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.isCamp}
                            error={errors.isCamp}
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Tak"
                              name="isCamp"
                              value="true"
                            />
                            <FormControlLabel
                              value="false"
                              control={<Radio />}
                              label="Nie"
                              name="isCamp"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      {values.isCamp == "true" && (
                        <>
                          <Grid item xs={12} style={{ marginTop: 15 }}>
                            <TextField
                              fullWidth
                              name="nameSurnameCampCommisioner"
                              id="nameSurnameCampCommisioner"
                              variant="filled"
                              error={
                                touched.nameSurnameCampCommisioner &&
                                Boolean(errors.nameSurnameCampCommisioner)
                              }
                              helperText={
                                touched.nameSurnameCampCommisioner &&
                                errors.nameSurnameCampCommisioner
                              }
                              label="Imię i nazwisko komendanta obozu (kierownika wypoczynku)"
                              value={values.nameSurnameCampCommisioner}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid item xs={12} style={{ marginTop: 15 }}>
                            <TextField
                              fullWidth
                              name="phoneNumberCampCommisioner"
                              id="phoneNumberCampCommisioner"
                              variant="filled"
                              error={
                                touched.phoneNumberCampCommisioner &&
                                Boolean(errors.phoneNumberCampCommisioner)
                              }
                              helperText={
                                touched.phoneNumberCampCommisioner &&
                                errors.phoneNumberCampCommisioner
                              }
                              label="Numer telefonu komendanta obozu (kierownika wypoczynku)"
                              value={values.phoneNumberCampCommisioner}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              type="number"
                            />
                          </Grid>
                        </>
                      )}
                      <Grid xs={12} style={{ marginTop: 15 }}>
                        <FormControl>
                          <FormLabel id="isDistrictCommisionerGuilty">
                            Czy Komendant Hufca jest osobą, która dopuściła się
                            przekroczenia?
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="isDistrictCommisionerGuilty"
                            name="isDistrictCommisionerGuilty"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.isDistrictCommisionerGuilty}
                            error={errors.isDistrictCommisionerGuilty}
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Tak"
                              name="isDistrictCommisionerGuilty"
                              value="true"
                            />
                            <FormControlLabel
                              value="false"
                              control={<Radio />}
                              label="Nie"
                              name="isDistrictCommisionerGuilty"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid xs={12} style={{ marginTop: 15 }}>
                        <FormControl>
                          <FormLabel id="isRegionalOfficeCommisionerGuilty">
                            Czy Komendant Chorągwi jest osobą, która dopuściła
                            się przekroczenia?
                          </FormLabel>
                          <RadioGroup
                            aria-labelledby="isRegionalOfficeCommisionerGuilty"
                            name="isRegionalOfficeCommisionerGuilty"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.isRegionalOfficeCommisionerGuilty}
                            error={errors.isRegionalOfficeCommisionerGuilty}
                          >
                            <FormControlLabel
                              control={<Radio />}
                              label="Tak"
                              name="isRegionalOfficeCommisionerGuilty"
                              value="true"
                            />
                            <FormControlLabel
                              value="false"
                              control={<Radio />}
                              label="Nie"
                              name="isRegionalOfficeCommisionerGuilty"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 15 }}>
                        <Stack
                          direction={{ xs: "column", sm: "row" }}
                          alignSelf="left"
                          spacing={1}
                          useFlexGap
                          sx={{ pt: 2, width: { xs: "100%", sm: "auto" } }}
                        >
                          <ColorButton
                            variant="contained"
                            type="submit"
                            disabled={isSubmitting}
                          >
                            PRZEŚLIJ
                          </ColorButton>
                          <ColorButton
                            onClick={() => handleReset()}
                            variant="contained"
                          >
                            ANULUJ
                          </ColorButton>
                        </Stack>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </Typography>
          </Stack>
        </Container>
      </SnackbarProvider>
    </Box>
  );
}
