import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/X";
import InstagramIcon from "@mui/icons-material/Instagram";
import YTIcon from "@mui/icons-material/YouTube";

import preval from "preval.macro";
const logoStyle = {
  width: "140px",
  height: "auto",
};

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {"Copyright © "}
      {new Date().getFullYear()}&nbsp;
      <Link href="https://zhp.pl/">Związek Harcerstwa Polskiego</Link>
    </Typography>
  );
}

function AppVersion() {
  const dateTimeStamp = preval`module.exports = new Date().toISOString();`;

  return (
    <Typography
      variant="body2"
      color="text.secondary"
      sx={{ mt: 1, opacity: 0.5 }}
    >
      gk&nbsp;•&nbsp;{process.env.REACT_APP_NAME}&nbsp;•&nbsp;v
      {process.env.REACT_APP_VERSION}&nbsp;•&nbsp;
      {dateTimeStamp.split("T")[0] +
        " " +
        dateTimeStamp.split("T")[1].split(".")[0]}
    </Typography>
  );
}

export default function Footer() {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { sm: 4, xs: 8 },
        py: { sm: 8, xs: 5 },
        textAlign: { xs: "center", md: "left" },
      }}
    >
      <Box
        sx={{
          display: { md: "block", lg: "flex" },
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div>
          <Link color="text.secondary" href="https://zhp.pl/rodo/">
            Polityka prywatności
          </Link>
          <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link
            color="text.secondary"
            href="https://zhp.pl/informacje-i-uwagi-prawne/"
          >
            Informacje i uwagi prawne
          </Link>
          <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="text.secondary" href="https://bip.zhp.pl/">
            Biuletyn Informacji Publicznej
          </Link>
          <Typography display="inline" sx={{ mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="text.secondary" href="#">
            Deklaracja dostępności
          </Link>
          <Copyright />
          <AppVersion />
        </div>
        <Stack
          direction="row"
          spacing={1}
          useFlexGap
          sx={{
            color: "text.secondary",
            justifyContent: { sm: "center", md: "left" },
            py: { sm: 1, xs: 1 },
            display: { xs: "none", sm: "block" },
          }}
        >
          <IconButton
            color="inherit"
            href="https://www.facebook.com/ZHPpl"
            aria-label="GitHub"
            sx={{ alignSelf: "center" }}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://twitter.com/zhp_pl/"
            aria-label="X"
            sx={{ alignSelf: "center" }}
          >
            <TwitterIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.linkedin.com/company/zwiazek-harcerstwa-polskiego"
            aria-label="LinkedIn"
            sx={{ alignSelf: "center" }}
          >
            <LinkedInIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.instagram.com/zhp_pl/"
            aria-label="LinkedIn"
            sx={{ alignSelf: "center" }}
          >
            <InstagramIcon />
          </IconButton>
          <IconButton
            color="inherit"
            href="https://www.youtube.com/@ZHP360"
            aria-label="LinkedIn"
            sx={{ alignSelf: "center" }}
          >
            <YTIcon />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}
