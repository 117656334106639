import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import SFH0 from "../assets/images/SFH0.png";
import ZHPGreenButton from "./Buttons/ZHPGreenButton";

export default function Hero() {
  return (
    <Box id="hero">
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 14, sm: 15 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} useFlexGap>
          <Box
            component="img"
            alt="Polityka Safe From Harm"
            src={SFH0}
            sx={{ width: "100%" }}
          />
          <Typography
            component="h2"
            variant="h4"
            color="text.primary"
            sx={{
              width: { sm: "100%", md: "100%" },
            }}
          >
            Centralny System Zgłoszeń Safe From Harm
          </Typography>
          <Typography
            textAlign="left"
            color="text.secondary"
            sx={{ alignSelf: "center" }}
          >
            Jeśli doświadczasz przemocy ze strony innego członka lub członkini
            ZHP, jesteś świadkiem sytuacji naruszenia granic lub dowiedziałaś/eś
            się o takiej sytuacji, zgłoś tę sprawę poprzez niniejszy formularz
            oraz poinformuj o niej komendanta hufca osoby poszkodowanej.
          </Typography>
          <Typography
            textAlign="left"
            color="text.secondary"
            sx={{ alignSelf: "center" }}
          >
            Zgłoszenie sprawy przez system pozwoli na wsparcie procesu
            reagowania w Twojej sprawie przez psychologa_żkę ze Strefy Pomocy
            ZHP. Wypełnij te pola, gdzie znasz i chcesz podać odpowiedzi.
            Pamiętaj, że im więcej informacji przekażesz, tym większe jest
            prawdopodobieństwo że uda się daną sytuację zaopiekować. Jeśli jest
            Ci trudno pisać o tej sytuacji, zgłoszenia możesz dokonać także
            drogą telefoniczną, dzwoniąc do Stefy Pomocy. Strefa Pomocy jest
            czynna w godzinach 15:00 - 21:00 w dni powszednie pod numerem 669
            116 116. Rozpoczynając rozmowę podkreśl, że dzwonisz w sprawie
            zgłoszenia sytuacji przekroczenia granic/doświadczenia przemocy.
          </Typography>
          <Typography
            textAlign="left"
            color="text.secondary"
            sx={{ alignSelf: "center" }}
          >
            Jeśli jest Ci trudno pisać o tej sytuacji, zgłoszenia możesz dokonać
            także drogą telefoniczną, dzwoniąc do Stefy Pomocy. Strefa Pomocy
            jest czynna w godzinach 15:00 - 21:00 w dni powszednie pod numerem
            669 116 116. Rozpoczynając rozmowę podkreśl, że dzwonisz w sprawie
            zgłoszenia sytuacji przekroczenia granic/doświadczenia przemocy.
          </Typography>

          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignSelf="center"
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: "100%", sm: "auto" } }}
          >
            <ZHPGreenButton to="/formularz">
              Formularz zgłoszenia przekroczenia granic
            </ZHPGreenButton>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}
