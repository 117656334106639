import React from "react";
import { Typography, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

export default function ZHPGreenButton({ children, to }) {
  const navigate = useNavigate();

  const ColorButton = styled(Button)(({ theme }) => ({
    color: "white",
    backgroundColor: "#86a315",
    "&:hover": {
      backgroundColor: "#587d18",
    },
  }));

  return (
    <ColorButton onClick={() => navigate(to)}>
      <Typography fontWeight={600}>{children}</Typography>
    </ColorButton>
  );
}
